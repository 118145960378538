<template>
  <div>
    <Breadcrumb :items="breadcrumb" />
    <div class="mx-2 mx-md-6">
      <v-container>
        <ProductDetail
          v-if="product.productId"
          :product="product"
          :selectedImage="selectedImage"
          :key="'product_detail_' + key"
          @selectImage="selectImage"
          @goToCustomerVote="goToCustomerVote"
          @chageProduct="chageProduct"
        />

        <ProductDescription
          v-if="product.productId"
          :product="product"
          :key="'product_description_' + product.productId + '_key_' + key"
          :productMetadata="product.metaData"
          :ingredientList="product.ingredientList"
        />
      </v-container>
    </div>
    <!-- default layout 220 -> Altri clienti hanno acquistato: -->
    <category-block
      :container="false"
      :target="category"
      :productId="product.productId"
      position="product1"
      class="category-block category-block-1"
    />

    <!-- default: crm layout 223 -> Ti potrebbe interessare... -->
    <category-block
      :container="false"
      :target="category"
      :productId="product.productId"
      position="product2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style lang="scss">
.product-detail-view {
  img.normal {
    height: 360px;
    width: 360px;
  }
  img.image-mobile {
    height: 336px;
    width: 336px;
  }
  .thumb-wrapper {
    img {
      width: 80px;
      height: 80px;
    }
  }
  .back-button {
    &:hover {
      opacity: 0.5;
    }
  }
  .description-column {
    min-height: 296px;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      min-height: 504px;
    }
  }
}
</style>
<script>
import join from "lodash/join";
import compact from "lodash/compact";
import get from "lodash/get";

import ProductDescription from "@/components/product/ProductDescription.vue";
import ProductDetail from "@/components/product/ProductDetail.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import categoryMixin from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

import ProductService from "~/service/productService";
import analyticsService from "~/service/analyticsService";

export default {
  name: "Product",
  mixins: [categoryMixin, deliveryReactive],
  jsonld() {
    if (this.product.productId) {
      let images = this.product.media.map(item => item.medium);
      return {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: this.product.name,
        image: images,
        description: this.product.description,
        sku: this.product.codInt,
        mpn: this.product.codInt,
        brand: {
          "@type": "Brand",
          name: this.product.shortDescr
        },
        // aggregateRating: {
        //   reviewCount: this.product.ratingSummary.total,
        //   ratingValue: this.product.ratingSummary.avg
        //   // bestRating: // ex: 5,0
        // },
        offers: {
          "@type": "Offer",
          url: process.env.VUE_APP_EBSN_URL + "/" + this.product.slug,
          priceCurrency: "EUR",
          price: this.product.price ? this.product.price : 0,
          priceValidUntil: "2050-12-31", // NON HO UNA DATA VALIDA DI PREZZO
          itemCondition: "https://schema.org/NewCondition",
          availability:
            this.product.available >= 0
              ? "https://schema.org/InStock"
              : "https://schema.org/OutOfStock"
        }
      };
    }
  },
  data() {
    return {
      product: {
        productId: null,
        name: "",
        shortDescr: "",
        description: ""
      },
      showNoProductCard: false,
      selectedImage: null,
      key: 1,
      layout20length: 0
    };
  },
  components: {
    ProductDescription,
    ProductDetail,
    Breadcrumb
  },
  computed: {
    breadcrumb() {
      return this.$store.getters["category/categoryPathById"](
        this.product.categoryId
      );
    },
    getFullName() {
      return join(
        compact([
          this.product.name,
          this.product.shortDescr,
          this.product.description
        ]),
        " "
      );
    }
  },
  methods: {
    async getProduct() {
      var data = await ProductService.getProductBySlug(this.slug);
      if (data) {
        this.product = data;
        if (this.product.categoryId) {
          this.setCategory(this.product.categoryId);
        }

        if (this.product && this.product.media && this.product.media.length) {
          this.selectImage(this.product.media[0]);
        }
      } else {
        this.showNoProductCard = true;
      }
    },
    async setProduct(product) {
      // var data = await ProductService.getProductBySlug(this.slug);
      if (product) {
        this.product = product;
        if (this.product.categoryId) {
          this.setCategory(this.product.categoryId);
        }
        if (this.product && this.product.media && this.product.media.length) {
          this.selectImage(this.product.media[0]);
        }
        this.key += 1;
        analyticsService.viewProductDetail(this.product);
      } else {
        this.showNoProductCard = true;
      }
    },
    loaded(productsFound) {
      this.layout20length = productsFound;
    },
    async setCategory(categoryId) {
      this.$store.dispatch("category/setCategory", {
        slug: categoryId,
        reset: false
      });
    },
    async selectImage(image) {
      this.selectedImage = image;
      this.selectedImage.thumb = image.medium;
    },
    async reload() {
      //this.$store.dispatch("category/resetFilters");
      //let slug = split(this.category.slug, "@")[0];
      //await this.setCategory(slug);
      await this.getProduct();
      this.key += 1;
    },
    goToCustomerVote() {
      // goToCustomerVote;
    },
    goBack() {
      this.$router.go(-1);
    },
    chageProduct(product) {
      this.slug = product.slug;
      this.getProduct();
    }
  },
  async created() {
    this.slug = this.$route.params.slug;
    this.setProduct(this.$route.params.product);
    // this.showNoProductCard = false;
    // await this.getProduct();
    // analyticsService.viewProductDetail(this.product);
  },
  watch: {
    async $route(to) {
      if (to.params.slug != this.slug) {
        this.slug = to.params.slug;
        this.setProduct(this.$route.params.product);
        // await this.getProduct();
        // analyticsService.viewProductDetail(this.product);
      }
    }
  },
  metaInfo() {
    if (this.product)
      return {
        title: this.$route.meta.gtm,
        link: [
          {
            rel: "canonical",
            href: `${window.location.origin}/product/${this.product.slug}`
          }
        ],
        meta: [
          {
            vmid: "description",
            name: "description",
            content:
              "Ordina online " +
              get(
                this.product,
                "metaData.product_seo.SEO_DESCRIPTION",
                this.getFullName
              ) +
              " - " +
              this.product.shortDescr +
              " " +
              this.product.codInt +
              " sul nostro ecommerce. Scopri le nostre offerte per la tua spesa online su alisupermercati.it o presso i nostri punti vendita"
          },
          // Open Graph Metas
          {
            vmid: "og:locale",
            property: "og:locale",
            content: "it_IT"
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: get(
              this.product,
              "metaData.product_seo.SEO_TITLE",
              this.getFullName
            )
          },
          {
            vmid: "og:type",
            property: "og:type",
            content: "product"
          },
          {
            vmid: "og:url",
            property: "og:url",
            content: location.href
          },
          {
            vmid: "og:image",
            property: "og:image",
            content: get(
              this.product,
              "media[0].large",
              window.location.host + global.config.socialLogo ||
                "/logo/social-logo.png"
            )
          },
          {
            vmid: "og:image:alt",
            property: "og:image:alt",
            content:
              "Ordina online " +
              get(
                this.product,
                "metaData.product_seo.SEO_DESCRIPTION",
                this.getFullName
              ) +
              " - " +
              this.product.shortDescr +
              " " +
              this.product.codInt +
              " sul nostro ecommerce. Scopri le nostre offerte per la tua spesa online su alisupermercati.it o presso i nostri punti vendita"
          },
          {
            vmid: "og:site_name",
            property: "og:site_name",
            content: "Alí Supermercati"
          },
          // Twitter Metas
          {
            vmid: "twitter:card",
            name: "twitter:card",
            content: "summary_large_image"
          },
          {
            vmid: "title:title",
            name: "twitter:title",
            content:
              "Ordina online " +
              get(
                this.product,
                "metaData.product_seo.SEO_DESCRIPTION",
                this.getFullName
              ) +
              " - " +
              this.product.shortDescr +
              " " +
              this.product.codInt +
              " sul nostro ecommerce. Scopri le nostre offerte per la tua spesa online su alisupermercati.it o presso i nostri punti vendita"
          },
          {
            vmid: "twitter:image",
            name: "twitter:image",
            content: get(
              this.product,
              "media[0].large",
              window.location.host + global.config.socialLogo ||
                "/logo/social-logo.png"
            )
          },
          {
            vmid: "twitter:url",
            name: "twitter:url",
            content: location.href
          }
        ]
      };
  }
};
</script>
